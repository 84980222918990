import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/css/css/src/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "accordion--collapse"
    }}>{`Accordion | Collapse`}</h1>
    <p>{`Build vertically collapsing accordions in combination with our Collapse JavaScript plugin.`}</p>
    <div className='box'>
  <strong>Attention</strong>: the use of the components needs the <span className='pink'>squid.js</span> included in the project
    </div>
    <div className='box'>
  <strong>Attention + 1</strong>: Probably the interaction via Javascript should not work well in SPA applications (independent of the framework React, Angular, Vuejs, etc). For now we have no implementation but we will create in the future, for now just use the styles and create your own implementation
    </div>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <p>{`To change default color use `}<inlineCode parentName="p">{`background-:color`}</inlineCode>{` on `}<inlineCode parentName="p">{`collapse-title`}</inlineCode></p>
    <div className='box'>
  <div className="accordion" data-accordion>
    <div className="collapse"> 
      <p className="collapse-title background-pink">
        Title 1
        <span className='icon'>
          <i className="far fa-chevron-down"></i>
        </span>
      </p>
      <div className="collapse-body">
        Content 1
      </div>
    </div>
    <div className="collapse"> 
      <p className="collapse-title background-blue">
        Title 2
        <span className='icon'>
          <i className="far fa-chevron-down"></i>
        </span>
      </p>
      <div className="collapse-body">
        Content 2
      </div>
    </div>
    <div className="collapse"> 
      <p className="collapse-title">
        Title 3
        <span className='icon'>
          <i className="far fa-chevron-down"></i>
        </span>
      </p>
      <div className="collapse-body">
        Content 3
      </div>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="accordion" data-accordion>
  <div class="collapse"> 
    <p class="collapse-title">
      <!-- Text Title or HTML -->
      <span class='icon'>
        <!-- Icon Optinal -->
      </span>
    </p>
    <div class="collapse-body">
      <!-- HTML Content -->
    </div>
  </div>
</div>
`}</code></pre>
    <h2 {...{
      "id": "open-only-one-per-time"
    }}>{`Open Only One per time`}</h2>
    <p>{`Just add `}<inlineCode parentName="p">{`only-one`}</inlineCode>{` to accordion container`}</p>
    <div className='box'>
  <div className="accordion only-one" data-accordion>
    <div className="collapse"> 
      <p className="collapse-title background-pink">
        Title 1
        <span className='icon'>
          <i className="far fa-chevron-down"></i>
        </span>
      </p>
      <div className="collapse-body black">
        Content 1
      </div>
    </div>
    <div className="collapse"> 
      <p className="collapse-title background-blue">
        Title 2
        <span className='icon'>
          <i className="far fa-chevron-down"></i>
        </span>
      </p>
      <div className="collapse-body black">
        Content 2
      </div>
    </div>
    <div className="collapse"> 
      <p className="collapse-title">
        Title 3
        <span className='icon'>
          <i className="far fa-chevron-down"></i>
        </span>
      </p>
      <div className="collapse-body black">
        Content 3
      </div>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="accordion only-one" data-accordion>
  <div class="collapse"> 
    <p class="collapse-title">
      <!-- Text Title or HTML -->
      <span class='icon'>
        <!-- Icon Optinal -->
      </span>
    </p>
    <div class="collapse-body">
      <!-- HTML Content -->
    </div>
  </div>
</div>
`}</code></pre>
    <h2 {...{
      "id": "disabled"
    }}>{`Disabled`}</h2>
    <p>{`Just add `}<inlineCode parentName="p">{`collapse-disabled`}</inlineCode>{` to collapse container. Also `}<inlineCode parentName="p">{`collapse-loading`}</inlineCode>{` force the close de collapse-body`}</p>
    <div className='box'>
  <div className="accordion only-one" data-accordion>
    <div className="collapse collapse-disabled"> 
      <p className="collapse-title background-pink">
        Title 1
        <span className='icon'>
          <i className="far fa-chevron-down"></i>
        </span>
      </p>
      <div className="collapse-body black">
        Content 1
      </div>
    </div>
  </div>
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<div class="accordion" data-accordion>
  <div class="collapse collapse-disabled"> 
    <p class="collapse-title">
      <!-- Text Title or HTML -->
      <span class='icon'>
        <!-- Icon Optinal -->
      </span>
    </p>
    <div class="collapse-body">
      <!-- HTML Content -->
    </div>
  </div>
</div>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      